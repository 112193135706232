import request from '@/utils/request'

export function getProArea(){
    return request({
        url: '/appreq/area/getAreaPro',
        method: 'get',
        headers: {
            isToken: false
        },
    })
}

export function getProJson(code){
    return request({
        url : 'https://geo.datav.aliyun.com/areas_v3/bound/'+code+'_full.json',
        method: 'get',
        headers: {
            isToken: false
        },
    })
}
