<template>
  <div class="container">
    <div class="container_box">
      <div class="container_box_left">
        <div
          id="myChart"
          class="container_box_left_map"
        ></div>
        <div class="container_box_left_info">
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">会员数</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.memberNum}}</div>
          </div>
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">站点数</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.stationNum}}</div>
          </div>
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">队伍数</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.teamNum}}</div>
          </div>
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">活动数</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.actNum}}</div>
          </div>
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">服务总时长</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.serverLength}}H</div>
          </div>
          <div class="container_box_left_info_item">
            <div class="container_box_left_info_item_title">兑换总金额</div>
            <div class="container_box_left_info_item_value">{{information == null ? 0 : information.orderMoney}}</div>
          </div>
        </div>
      </div>
      <div class="container_box_right">

        <!-- <el-carousel  type="card" height="700px" direction="vertical" :autoplay="false" :initial-index="0" >
                    <el-carousel-item v-for="item in areaData" :key="item" @click="changeProvince(item)">
                        <div class="container_box_right_item">{{ item.name }}</div>
                    </el-carousel-item>
                </el-carousel> -->
        <div
          class="container_box_right_item"
          v-for="item in areaData"
          :key="item"
          @click="changeProvince(item)"
        >{{item.name}}</div>

      </div>
    </div>

  </div>
</template>
<script setup name="ActMap">
import * as echarts from 'echarts'
import { onMounted, ref } from 'vue-demi'
//import axios from 'axios'
import { getProArea } from "@/api/area.js"
import { informationcountByLant } from "@/api/home";
import allJson from "../../assets/100000_full.json"
import json1 from "../../assets/110000_full.json"
import json2 from "../../assets/120000_full.json"
import json3 from "../../assets/130000_full.json"
import json4 from "../../assets/140000_full.json"
import json5 from "../../assets/150000_full.json"
import json6 from "../../assets/210000_full.json"
import json7 from "../../assets/220000_full.json"
import json8 from "../../assets/230000_full.json"
import json9 from "../../assets/310000_full.json"
import json10 from "../../assets/320000_full.json"
import json11 from "../../assets/330000_full.json"
import json12 from "../../assets/340000_full.json"
import json13 from "../../assets/350000_full.json"
import json14 from "../../assets/360000_full.json"
import json15 from "../../assets/370000_full.json"
import json16 from "../../assets/410000_full.json"
import json17 from "../../assets/420000_full.json"
import json18 from "../../assets/430000_full.json"
import json19 from "../../assets/440000_full.json"
import json20 from "../../assets/450000_full.json"
import json21 from "../../assets/460000_full.json"
import json22 from "../../assets/500000_full.json"
import json23 from "../../assets/510000_full.json"
import json24 from "../../assets/520000_full.json"
import json25 from "../../assets/530000_full.json"
import json26 from "../../assets/540000_full.json"
import json27 from "../../assets/610000_full.json"
import json28 from "../../assets/620000_full.json"
import json29 from "../../assets/630000_full.json"
import json30 from "../../assets/640000_full.json"
import json31 from "../../assets/650000_full.json"
import json32 from "../../assets/810000_full.json"
import json33 from "../../assets/820000_full.json"


const query = ref({});
const information = ref();
const areaData = ref([])
const code = ref(370000)

const loadArea = async () => {
  let result = await getProArea()
  if (result.code === 200) {
    areaData.value = result.data
  }
}
loadArea()

const changeProvince = (item) => {
  code.value = item.code
  initMap()
  query.value = {
    provinceId:code.value
  }
  getInformation();
}

//获取首页信息
const getInformation = async () => {
  let response = await informationcountByLant(query.value);
  if (response.code == 200) {
    information.value = response.data
  }
}
getInformation();
onMounted(() => {
  initMap()

})
const initMap = () => {
  //let url = 'https://geo.datav.aliyun.com/areas_v3/bound/'+code.value+'_full.json'
  //let result = await axios.get(url);
  let result = {}
  switch (code.value) {
    case 100000:
      result = allJson;
      break;
    case 110000:
      result = json1;
      break;
    case 120000:
      result = json2;
      break;
    case 130000:
      result = json3;
      break;
    case 140000:
      result = json4;
      break;
    case 150000:
      result = json5;
      break;
    case 210000:
      result = json6;
      break;
    case 220000:
      result = json7;
      break;
    case 230000:
      result = json8;
      break;
    case 310000:
      result = json9;
      break;
    case 320000:
      result = json10;
      break;
    case 330000:
      result = json11;
      break;
    case 340000:
      result = json12;
      break;
    case 350000:
      result = json13;
      break;
    case 360000:
      result = json14;
      break;
    case 370000:
      result = json15;
      break;
    case 410000:
      result = json16;
      break;
    case 420000:
      result = json17;
      break;
    case 430000:
      result = json18;
      break;
    case 440000:
      result = json19;
      break;
    case 450000:
      result = json20;
      break;
    case 460000:
      result = json21;
      break;
    case 500000:
      result = json22;
      break;
    case 510000:
      result = json23;
      break;
    case 520000:
      result = json24;
      break;
    case 530000:
      result = json25;
      break;
    case 540000:
      result = json26;
      break;
    case 610000:
      result = json27;
      break;
    case 620000:
      result = json28;
      break;
    case 630000:
      result = json29;
      break;
    case 640000:
      result = json30;
      break;
    case 650000:
      result = json31;
      break;
    case 810000:
      result = json32;
      break;
    case 820000:
      result = json33;
      break;
  }
  if (result == null || result == undefined) {
    result = json15
  }
  console.log(result)
  let myChart = echarts.init(document.getElementById("myChart"))
  echarts.registerMap('chinaMap', result)
  let option = {
    geo: {
      type: 'map',
      map: 'chinaMap', //chinaMap需要和registerMap中的第一个参数保持一致
      roam: true, // 设置允许缩放以及拖动的效果
      label: {
        show: true  //展示标签
      },
      zoom: 1.2, //设置初始化的缩放比例
      //center: [87.617733,43.792818] //设置地图中心点的坐标 （图为将新疆设置为地图中心点）
    }
  }
  myChart.setOption(option)
  myChart.on('click', function (params) {
      const name = params.name;
      query.value = {
        cityName:name
      }
      getInformation();
  });
  window.onresize = function () {//自适应大小
    myChart.resize();
  };
}
//initMap()
</script>
<style lang="scss" scoped>
.container {
  //   width: 100%;
  //   height: 100vh;
  background-color: #f5f5f5;
  //padding-top: 30px;
  padding: 30px 30px 30px 30px;
  &_box {
    display: flex;
    flex-direction: row;
    &_left {
      border-radius: 8px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 8;
      &_map {
        flex: 8;
        //align-self: center;
        //width: 880px;
        height: 100vh;
      }
      &_info {
        height: 100vh;
        padding: 60px 60px 0px 60px;
        &_item {
          margin-bottom: 40px;

          &_title {
            font-size: 36px;
            color: #505559;
            text-align: right;
          }
          &_value {
            margin-top: 15px;
            font-size: 14px;
            color: #505559;
            text-align: right;
          }
        }
      }
    }
    &_right {
      border-radius: 8px;
      padding: 50px 50px;
      background-color: #fff;
      flex: 1;
      height: 100vh;
      overflow-y: scroll;
      &_item {
        line-height: 100px;
        color: #ff6200;
        font-size: 20px;
      }
    }
  }
}
:deep(.el-carousel__container) {
  height: 700px;
}
.el-carousel__item {
  height: 100px;
}
.el-carousel__item h3 {
  //color: #475669;
  opacity: 0.75;
  line-height: 50px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  //background-color: #d3dce6;
}
</style>

